import React from 'react'
import { graphql, navigate } from 'gatsby'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FaEnvelope, FaFacebook, FaInstagram, FaMapMarkedAlt, FaPhoneAlt, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Seo from '../components/Seo'
import { CheckboxInput, RadioInput, SelectInput, Textarea, TextInput } from '../components/FormikInputs'
import encode from '../helpers/netlifyFormEncode'

import * as styles from '../styles/pages/contact.module.scss'

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  // learningMethods: [],
  learningMethod: '',
  studyField: '',
  course: '',
  message: '',
  receiveUpdates: true
}

const formValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required"),
  cellphone: Yup.string().required("Cellphone is required"),
  learningMethod: Yup.string(),
  studyField: Yup.string(),
  course: Yup.string(),
  message: Yup.string(),
  receiveUpdates: Yup.boolean()
})

const ContactPage = ({ data }) => {
  const { socialMediaLinks, ...contactInfo } = data.sanityCompanyInfo
  const studyFields = data.allSanityStudyField.nodes
  const courses = data.allSanityCourse.nodes

  const studyFieldOptionElements = studyFields.map(field => (
    <option key={field.title} value={field.title}>{field.title}</option>
  ))

  const courseOptionElements = (studyField) => {
    const filteredCourses = courses.filter(course => {
      const courseStudyFields = course.studyFields.map(field => field.title)
      return courseStudyFields.includes(studyField)
    })

    const filteredCourseOptionElements = filteredCourses.map(course => (
      <option key={course.title} value={course.title}>{course.title}</option>
    ))

    return (<>
      <option value=''>Choose a course</option>
      {filteredCourseOptionElements}
    </>)
  }

  // const formSubmitHandler = async (values, { resetForm }) => {
  //   try {
  //     // NETLIFY FORM
  //     await fetch("/", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //       body: encode({ 
  //         "form-name": "contact", 
  //         ...values 
  //       })
  //     })

  //     // CAMPUSONLINE POST
  //     await fetch("https://unicollege.campusonline.co.za/registration/student-registration.php", {
  //       method: "POST",
  //       mode: "no-cors",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         requestType: 'contact',
  //         ...values
  //       })
  //     })

  //     if (values.receiveUpdates) {
  //       addToMailchimp(values.email, {
  //         FNAME: values.firstName,
  //         LNAME: values.lastName,
  //         ...values.learningMethod === 'Full Time' && { 'group[80825][1]': true },
  //         ...values.learningMethod === 'Part Time' && { 'group[80825][2]': true },
  //         ...values.learningMethod === 'Online' && { 'group[80825][4]': true },
  //         ...values.learningMethod === 'Workshops' && { 'group[80825][16384]': true },
  //       })
  //     }

  //     resetForm()
  //     navigate("/thank-you-contact/")
  //   } catch(error) {
  //     console.log(error)
  //     alert("Sorry, something went wrong. Please try again.")
  //   }
  // }

  //// Testing safe Facebook Pixel tracking
  const formSubmitHandler = async (values, { resetForm }) => {
    try {
      // First handle your form submissions as before
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ 
          "form-name": "contact", 
          ...values 
        })
      })
  
      await fetch("https://unicollege.campusonline.co.za/registration/student-registration.php", {
        method: "POST",
        mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          requestType: 'contact',
          ...values
        })
      })
  
      // Handle Mailchimp
      if (values.receiveUpdates) {
        addToMailchimp(values.email, {
          FNAME: values.firstName,
          LNAME: values.lastName,
          ...values.learningMethod === 'Full Time' && { 'group[80825][1]': true },
          ...values.learningMethod === 'Part Time' && { 'group[80825][2]': true },
          // ...values.learningMethod === 'Online' && { 'group[80825][4]': true },  //remove as requested
          ...values.learningMethod === 'Workshops' && { 'group[80825][16384]': true },
        })
      }
  
      // Add safe Facebook Pixel tracking
      if (typeof window !== 'undefined' && window.fbq) {
        // Track the lead without PII data
        window.fbq('track', 'Lead', {
          content_name: 'Contact Form Submission',
          content_category: values.studyField || 'General Enquiry',
          learning_method: values.learningMethod || 'Not Specified',
          course_interest: values.course || 'Not Specified',
          // Do NOT include: email, name, phone, or any other PII
        });
      }
  
      resetForm()
      navigate("/thank-you-contact/")
    } catch(error) {
      console.log(error)
      alert("Sorry, something went wrong. Please try again.")
    }
  }

  return (
    <main className={styles.contactPage}>
      <Seo title="Contact Us" pathname='/contact' />
      {/* Note: The .page-container class is on this div and not on the main element because of the map below. */}
      <div className="page-container">
        <h1>Contact Us</h1>
        <p>Complete the enquiry form below and a Student Adviser will contact you shortly!</p>

        <section className={styles.pageContent}>
          <div>
            <div className={styles.contactInfo}>
              <ul id='contact-info-list'>
                <li>
                  <FaMapMarkedAlt />
                  <a href='https://www.google.com/maps/dir//UniCollege,+17+Clew+St,+Kenmare,+Krugersdorp,+1739/@-26.1111342,27.8055818,17z' target='_blank' rel='noreferrer'>
                    <span dangerouslySetInnerHTML={{__html: contactInfo.address.replaceAll('\n', '<br>')}} />
                  </a>
                </li>
                <li>
                  <FaEnvelope />
                  <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                </li>
                <li>
                  <FaPhoneAlt />
                  <a href={`tel:${contactInfo.telephoneNumber.replaceAll(' ', '')}`}>{contactInfo.telephoneNumber}</a>
                </li>
                <li>
                  <FaWhatsapp />
                  <a href={`https://wa.me/+27${contactInfo.whatsappNumber.replaceAll(' ', '')}`} target='_blank' rel='noreferrer'>{contactInfo.whatsappNumber}</a>
                </li>
              </ul>

              <div className={styles.socialLinks}>
                <a href={socialMediaLinks.facebook} title='Facebook' aria-label='Facebook' target='_blank' rel='noreferrer'>
                  <FaFacebook />
                </a>
                <a href={socialMediaLinks.instagram} title='Instagram' aria-label='Instagram' target='_blank' rel='noreferrer'>
                  <FaInstagram />
                </a>
                <a href={socialMediaLinks.youtube} title='YouTube' aria-label='YouTube' target='_blank' rel='noreferrer'>
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>

          {/* FORM */}
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({ isSubmitting, values }) => (
              <Form
                name="contact"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                className={`${styles.contactForm} form-primary`}
                data-fb-pixel-inhibit="true"
                data-meta-pixel-inhibit="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <TextInput
                  label="First Name"
                  name="firstName"
                  type="text"
                />
                <TextInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                />
                <TextInput
                  label="Email"
                  name="email"
                  type="email"
                />
                <TextInput
                  label="Cellphone"
                  name="cellphone"
                  type="tel"
                />

                <fieldset>
                  <legend>What learning method are you interested in?</legend>
                  <RadioInput name="learningMethod" value="Full Time">
                    Full Time
                  </RadioInput>
                  <RadioInput name="learningMethod" value="Part Time">
                    Part Time
                  </RadioInput>
                  {/* <RadioInput name="learningMethod" value="Online">
                    Online
                  </RadioInput> */}
                  <RadioInput name="learningMethod" value="Workshops">
                    Workshops
                  </RadioInput>
                  {/* <CheckboxInput name="learningMethods" value="Full Time">
                    Full Time
                  </CheckboxInput>
                  <CheckboxInput name="learningMethods" value="Part Time">
                    Part Time
                  </CheckboxInput>
                  <CheckboxInput name="learningMethods" value="Online">
                    Online
                  </CheckboxInput>
                  <CheckboxInput name="learningMethods" value="Workshops">
                    Workshops
                  </CheckboxInput> */}
                </fieldset>

                <fieldset>
                  <legend>What course/study field are you interested in?</legend>
                  <SelectInput label="Study Field" name="studyField">
                    <option value="">Choose a study field</option>
                    {studyFieldOptionElements}
                  </SelectInput>
                  <SelectInput label="Course" name="course">
                    {!values.studyField 
                      ? <option value=''>Please choose a study field first</option>
                      : courseOptionElements(values.studyField)
                    }
                  </SelectInput>
                </fieldset>

                <Textarea label="Message" name="message" className="c-span-full" rows="10" />

                <CheckboxInput name="receiveUpdates" className="c-span-full">
                  I would like to receive updates on course start dates and new course information
                </CheckboxInput>

                <span className="c-span-full">
                  <button type="submit" disabled={isSubmitting}>Submit</button>
                </span>
              </Form>
            )}
          </Formik>
        </section>
      </div>

      {/* --- MAP --- */}
      <iframe 
        className={styles.map} 
        title="google-map" 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.6258540981344!2d27.80339311564083!3d-26.111129366538602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95991893422267%3A0x7fd76137467a43a8!2sUniCollege%20West%20Rand!5e0!3m2!1sen!2sza!4v1628671743101!5m2!1sen!2sza" 
        loading="lazy" 
      />
    </main>
  )
}

export const query = graphql`
  query TestContactPageQuery {
    sanityCompanyInfo {
      address
      email
      telephoneNumber
      whatsappNumber
      socialMediaLinks {
        facebook
        instagram
        youtube
      }
    }
    allSanityStudyField(sort: {fields: title}) {
      nodes {
        title
      }
    }
    allSanityCourse(sort: {fields: title}) {
      nodes {
        title
        studyFields {
          title
        }
      }
    }
  }
`

export default ContactPage